import { IOrderable } from './IOrderable';
import { GlobalService } from '../services/global-service';

export class Upload implements IOrderable {
  public name: string;
  public url: string;
  public order: number;
}

export class Product implements IOrderable {
  public uid: string;
  public audioURLs: Upload[];
  public brief_description: string;
  public category: string;
  public downloadURL: string;
  public full_description: string;
  public imageURLs: Upload[];
  public videoPreview: string;
  public name: string;
  public price: string;
  public version: string;
  public order: number;
  public isLegacy: boolean;
  public bundleIds: string[];
  public vst: false;
  public vst3: false;
  public lv2: false;
  public au: false;
  public aax: false;
  public installer: false;
  public discontinued: boolean;
  public docPageUrl: string;
  public isFreemium: boolean;
  public isEnabled: boolean;
}

export function Sort(arr: Product[]): Product[] {
  arr.forEach((product) => {
    if (product.imageURLs) {
      product.imageURLs = SortUpload(GlobalService.ToArray(product.imageURLs));
    } else {
      product.imageURLs = [];
    }
    if (product.audioURLs) {
      product.audioURLs = SortUpload(GlobalService.ToArray(product.audioURLs));
    } else {
      product.audioURLs = [];
    }
  });
  const sorted = arr.sort((a, b) => Number(b.order) - Number(a.order));
  return sorted;
}

export function SortUpload(arr: Upload[]): Upload[] {
  const sorted = arr.sort((a, b) => Number(b.order) - Number(a.order));
  return sorted;
}

export function prettyVersion(product: Product) {
  return `v${product.version.replace(/-/g, '.')}`;
}
