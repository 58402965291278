































































































































import Vue from 'vue';
import { database, auth } from 'firebase/app';
import { Product, Sort } from '../models/product';
import { NewsPost } from '../models/newsPost';
import { EmailTemplate } from '../models/emailTemplate';
import { GlobalService } from '../services/global-service';

import { Payment } from '../models/payment';
import { Sale } from '../models/sale';
import SalesReport from './SalesReport.vue';

// import GraphLine3D from 'vue-graph/src/components/line3d.js'
// import NoteWidget from 'vue-graph/src/widgets/note.js'
// import LegendWidget from 'vue-graph/src/widgets/legends.js'

// Vue.component(GraphLine3D.name, GraphLine3D);
// Vue.component(NoteWidget.name, NoteWidget);
// Vue.component(LegendWidget.name, LegendWidget);

export default Vue.extend({
  data() {
    return {
      signedIn: false,
      products: [] as Product[],
      emailTemplates: [] as EmailTemplate[],
      payments: [],
      labels: [new Date(), new Date()],
      values: [],
      giftPluginId: '',
      giftUserId: '',
    };
  },
  methods: {
    manageVersion(product: Product) {
      this.$router.push({ name: 'versions', params: { product: product.name } });
    },
    track() {
      //this.$ga.page('/');
    },
    giftPlugin(userId: string, pluginId: string) {
      database().ref(`/products/${this.giftPluginId}`).on('value', (snapshot) => {
        let product: Product = snapshot.val();
        if(product)
          database().ref(`downloads/${this.giftUserId}`).push(product.uid);
      })
      
    },
    editProduct(product: Product) {
      this.$router.push(`/edit_product/${product.name}`);
    },
    createProduct() {
      this.$router.push({ name: 'edit_product' });
    },
    editSale(sale: Sale) {
      this.$router.push({name : 'edit_sale', params: {uid : sale.uid}});
    },
    createSale() {
      this.$router.push({name : 'new_sale'});
    },
    editEmailTemplate(emailTemplate: EmailTemplate) {
      this.$router.push(`/edit_email_template/${emailTemplate.name}`);
    },
    createEmailTemplate(emailTemplate: EmailTemplate) {
      this.$router.push({ name: 'edit_email_template' });
    },
    updateOrder(product: Product) {
      database().ref(`/products`).orderByChild('name').equalTo(product.name).once('value', (snapshot) => {
        const key = Object.keys(snapshot.val())[0];
        database().ref(`/products/${key}/order`).set(product.order);
      });
    },
    pushPaymentToGraph(snapshot: any) {
      const date = new Date(snapshot.date).toDateString();
      const found = false;
      this.values.forEach((element) => {
        const inDate = new Date(element[0]).toDateString();
        if (inDate === date) {
          element[1] += snapshot.amount / 100;
        }
      });
      if (!found) {
        this.values.push([new Date(snapshot.date), snapshot.amount / 100]);
      }
    },
    getPayments() {
      
    },
    editNewsPost(postId: string) {
      this.$router.push(`/edit_news_post/${postId}`);
    },
    createNewsPost() {
      this.$router.push(`/edit_news_post`);
    },
    testError() {
      throw new Error;
    }
  },
  computed: {
    newsPosts: {
      get(): NewsPost[] {
        return this.$store.getters.newsPosts;
      }
    },
    sales: {
      get(): Sale[] {
        return this.$store.getters.salesAll;
      }
    }
  },
  created() {
    this.$store.dispatch('loadNewsPostsFromFirebase');
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.labels[0] = d;
    auth().onAuthStateChanged((user) => {
      if (user) {
        console.log();
        this.getPayments();
        database().ref('/products').on('value', (snapshot) => {
          this.products = Sort(GlobalService.ToArray(snapshot.val()));
        });

        database().ref('/emailTemplates').on('value', (snapshot) => {
          this.emailTemplates = GlobalService.ToArray(snapshot.val());
        });
      }
    });
  },
  components: {
    SalesReport,
  },
});
