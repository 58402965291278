



































































































import Vue from 'vue';
const description = `Cut Through Recordings is a progressive audio plugin creator. We strive to create innovative plugins at a more than affordable price.  Come check out a selection of excellent sounding plugins!`;
export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - Home',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: description },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - Home' },
      { itemprop: 'description', content: description },
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F7618318a73af136f1ca2ca9e6ff69901%2FAllPluginsBundle-April2020.png?alt=media&token=fe27b3ad-7bab-4b64-a576-be3c0ef17872' },
      { itemProp: 'og:title', content: 'Cut Through Recordings - Home'},
      { itemProp: 'og:description',  content: description },
      { itemProp: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F7618318a73af136f1ca2ca9e6ff69901%2FAllPluginsBundle-April2020.png?alt=media&token=fe27b3ad-7bab-4b64-a576-be3c0ef17872'}
    ],
  },
  data() {

    return {
      isMobile: false,
    };
  },

});
