



























































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Product, prettyVersion } from '../models/product';
import AudioPlayer from './AudioPlayer.vue';
import { database, auth } from 'firebase/app';
import { GlobalService } from '../services/global-service';
import { SaleService } from '../services/sale-service';
import md5 from 'md5';
import DownloadDialogVue from './DownloadDialog.vue';
import { Meta } from '../decorators';
import firebase from 'firebase/app';
import { MetaInfo } from 'vue-meta';
import { productNameToMetaInfo } from '../config/meta'



@Component({
  components: {
    'v-audio-player': AudioPlayer,
    'v-download-dialog': DownloadDialogVue,
  },
})
export default class extends Vue {
  public productName: string = '';
  public loggedIn: boolean = false;
  public showModal: boolean = false;
  public showDownloadModal: boolean = false;
  public imageWidth: number = 1000;
  
  public bundledProducts: Product[] = [];
  public selectedImageIndex = 0;

  @Meta
  public metaInfo() {
    return productNameToMetaInfo(this.productName, this.$route.fullPath);
  }

  @Watch('metaInfo')
  public onMetaInfoChanges() {
    // console.log('meta info changed');
  }

  get plainDescription() {
    return this.product.full_description.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, ' ').replace(/&nbsp;/g, ' ');
  }

  get onSale() {
    return SaleService.OnSale(this.product);
  }

  get salePrice() {
    return SaleService.SalePrice(this.product);
  }

  get product(): Product {
    const p = this.$store.getters.productInView;
    this.bundledProducts = [];
    if (p.category === 'Bundle') {
      p.bundleIds.forEach((bundleId) => {
        database().ref(`/products/${bundleId}`).once('value', (snapshot) => {
          const p2 = snapshot.val();
          p2.imageURLs = GlobalService.ToArray(p2.imageURLs);
          this.bundledProducts.push(p2);
        });
      });
    }
    // this.getImageWidth();
    return p;
  }

  get price(): string {
    return SaleService.SalePrice(this.product);
  }

  @Watch('product')
  onProductChanged(newProduct, oldProduct) {
    this.getImageWidth();
  }

  get currentVersion(): string {
    if (this.product.version) {
      return prettyVersion(this.product);
    } else { return ''; }
  }

  public created() {
    this.bundledProducts = [];
    this.productName = this.$route.params.name.replace(/_/g, ' ');
    // this.metaInfo = productNameToMetaInfo(this.productName);
    const hash = md5(this.productName);
    this.$store.dispatch('loadSingleProductFromFirebase', hash);

    auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }

    });
  }

  public track() {
    // this.$ga.page(`/product/${this.product.name}`);
  }
  public getImageWidth() {
    const img = new Image();
    img.onload = () => {
      this.imageWidth = img.width;
      // console.log(img);
    };
    img.src = this.product.imageURLs[0].url;
  }

  public imageClicked(upload: any) {
    // console.log(upload);s
  }

  public addToCart() {
    if (this.loggedIn && this.product.name !== '') {
      firebase.analytics().logEvent('addToCart', {user: auth().currentUser, product: this.product});
      this.$store.dispatch('addToCart', this.product.uid);
    } else {
      this.$store.commit('showSignIn', true);
    }
  }
}
