

































































































import Vue from 'vue';
import PayPal from 'vue-paypal-checkout';
import { database } from 'firebase/app';

export default Vue.extend({
  data() {
    return {
      thankYouModal: false as boolean,
      emailListModal: false as boolean,
      emailAddress: '' as string,
      paypal: {
        style: {
          label: 'checkout',
          size: 'small',    // small | medium | large | responsive
          shape: 'rect',         // pill | rect
          color: 'blue',         // gold | blue | silver | black
        },
        credentials: {
          sandbox: process.env.VUE_APP_PAYPAL_KEY,
          production: process.env.VUE_APP_PAYPAL_KEY,
        },
      },
    };
  },
  name: 'v-ctr-footer',
  methods: {
    paypalCompleted() {
      this.thankYouModal = true;
    },
    subscribeToNewsletter() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.emailAddress !== '' && re.test(String(this.emailAddress).toLowerCase())) {
        database().ref('/emailList').push(this.emailAddress).then((x) => {
          this.emailAddress = '';
          this.emailListModal = true;
        });
      }
    },

  },
  computed: {
    getEnv: {
      get() {
        const mode = process.env.NODE_ENV;
        return mode === 'production' ? 'production' : 'sandbox';
      },
    },
  },
  created() {
  },
  components: {
    PayPal,
  },
});
