
















































































































































































































































import Vue from 'vue';
import { Database } from './services/database';
import { Authentication } from './services/authentication';
import { functions, database, auth } from 'firebase/app';
import { GlobalService } from './services/global-service';
// import SignUp from './components/SignUp.vue';
import { Sort } from './models/product';
import { setTimeout } from 'timers';
import CTRFooter from './components/CTRFooter.vue';
import { mapState } from 'vuex';
import { Sale } from './models/sale';

const description = `Cut Through Recordings is a progressive audio plugin creator. We strive to create innovative plugins at a more than affordable price.  Come check out a selection of excellent sounding plugins!`;
export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - Store',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: description },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - Store' },
      { itemprop: 'description', content: description },
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FCTR_Icon2.png?alt=media&token=17d36c9e-1883-465c-933c-51053f8ec888' },
    ],
  },
  data() {
    return {
      drawer: false,
      clipped: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Cut Through Recordings',
      authenticated: false,
      username: '',
      showIcon: false,
      isAdmin: false,
      productLinks: [
        { name: 'All Plugins Bundle', link: 'All_Plugins_Bundle' },
        { name: 'Convergence', link: 'Convergence'},
        { name: 'EpicPRESS', link: 'EpicPRESS' },
        { name: 'M4 Multiband Compressor', link: 'M4_Multiband_Compressor' },
        { name: 'Entropy II', link: 'Entropy_II_-_Enhanced_Stereo_Delay' },
        { name: 'Faradelay', link: 'Faradelay' },
        { name: 'POP Sound Shaper', link: 'POP_Sound_Shaper' },
      ],
    };
  },
  computed: {
    saleRunning: {
      get(): boolean {
        const sales: Sale[] = this.$store.getters.sales;
        return sales.filter((sale) => {
          const start: Date = new Date(sale.startDate);
          const end: Date = new Date(sale.endDate);
          const current: Date = new Date();
          return current.getTime() >= start.getTime() && current.getTime() <= end.getTime(); 
        }).length > 0;
      },
    },
    cartLength: {
      get(): number {
        return this.$store.getters.cartLength;
      },
    },
    showLoginConfirmation: {
      get(): boolean {
        return this.$store.getters.showLoginConfirmation;
      },
      set(value: boolean): void {
        this.$store.commit('showLoginConfirmation', value);
      },
    },
    showCartErrorDialog: {
      get(): boolean {
        return this.$store.getters.showCartWarningModal;
      },
      set(value: boolean): void {
        this.$store.commit('showCartWarningModal', value);
      },
    },
    showDownloadErrorDialog: {
      get(): boolean {
        return this.$store.getters.showDownloadsWarningModal;
      },
      set(value: boolean): void {
        this.$store.commit('showDownloadsWarningModal', value);
      },
    },
  },
  methods: {
    prettyDate(dateString: string) {
      var monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
      ];
      const date = new Date(dateString);
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      return `${monthNames[monthIndex]} ${day} ${year}`;
    },
    doSignIn() {
      // this.$store.commit('showSignIn', true);
      this.$router.push({name: 'auth'});
      this.drawer = false;
    },
    signOut() {
      auth().signOut();
      this.drawer = false;
      this.$router.push({ name: 'home' });
    },
    goTo(name: any) {
      this.drawer = false;
      this.$router.push({name});
    },
    goToProduct(name: any) {
      this.drawer = false;
      this.$router.push({name: 'product', params: {name}});
    },
  },
  created() {
    this.$store.dispatch('loadNewsPostsFromFirebase');
    this.$store.dispatch('loadSalesFromFirebase');

    setTimeout((x) => { this.showIcon = true; }, 1000);
    Database.getProducts().then((products) => {
      products = Sort(products);
      this.$store.commit('products', products);
    });

    const vm = this;
    auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('loadCartFromFirebase');
        this.$store.dispatch('loadDownloadsFromFirebase');
        vm.authenticated = true;
        vm.username = user.email;
        database().ref(`users/${auth().currentUser.uid}/role`).once('value', (snapshot: any) => {
          const role = snapshot.val();
          this.isAdmin = role === 'admin';
        });
      } else {
        vm.authenticated = false;
        vm.username = '';
      }
    });
  },
  name: 'App',
  components: {
    // 'v-signup-dialog': SignUp,
    'v-ctr-footer': CTRFooter,
  },
});
