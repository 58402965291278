






























































import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - About',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'About the developer.' },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - About' },
      {        itemprop: 'description', content: `I started Cut Through Recordings to give a name to the home recording business that I was attempting to
                  get off the ground several years ago.  I produced all of my band's music and recorded a few local bands.`},
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FCTR_Icon2.png?alt=media&token=17d36c9e-1883-465c-933c-51053f8ec888' },
    ],
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    track() {
      //this.$ga.page('/');
    },
  },
  mounted() {
    setTimeout((x: any) => { this.show = true; }, 500);
  },
});
