








import Vue from 'vue';
import { Product } from '../models/product';
import { GlobalService } from '../services/global-service';
import md5 from 'md5';
import { database, auth } from 'firebase/app';

export default Vue.extend({
  data() {
    return {
      productName: '' as string,
      product: {
        name: '',
        full_description: '',
        audioURLs: [],
        downloadURL: '',
        imageURLs: [],
        brief_description: '',
        category: '',
        price: '',
        order: 0,
        version: '',
        isLegacy: false,
      } as Product,
    };
  },
  methods: {
    loadProduct() {
      this.productName = this.$route.params.product;
      const hash = md5(this.productName);
      database().ref(`/products/${hash}`).on('value', (snapshot) => {
        if (snapshot) {
          this.product = snapshot.val();
          console.log(this.product);
        }
      });
    },
  },
  created() {
    this.loadProduct();
  },
});
