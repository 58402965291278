export interface Payment {
  amount: number;
  date: string;
  token: any;
  type: string;
}

export const excludeEmailList = [
  'ethanepr@hotmail.com',
  'abaga1299@gmail.com',
  'admin@cutthroughrecordings.com',
  'ctrecordingslive@gmail.com',
  'ctrecordingslive-test@gmail.com'
]