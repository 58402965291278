import {storage} from 'firebase/app';

export class StorageService {
    public static upload(fileToUpload: File, path: string): Promise<string>  {
        const storageRef = storage().ref(`${path}/${fileToUpload.name}`);
        return storageRef.put(fileToUpload).then((snapshot) => {
            return storageRef.getDownloadURL();
        });
    }
}
