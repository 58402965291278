































import Vue from 'vue';
import Component from 'vue-class-component';
import { Sale } from '@/models/sale';
import { Product } from '@/models/product';
import { database } from 'firebase/app';
import md5 from 'md5'

interface productCheckBox {
  product: Product;
  checked: boolean;
}

@Component({})
export default class EditSale extends Vue {
  public sale: Sale = {
    uid: '',
    name: '',
    description: '',
    startDate: new Date().toISOString().substr(0, 10),
    endDate: new Date().toISOString().substr(0, 10),
    percentage: 0,
    products: []
  };

  created() {
    const uid = this.$route.params.uid;
    if ( uid !== undefined) {
      const sales = this.$store.getters.salesAll;
      console.log(uid);
      console.log(sales)
      this.sale = sales.find(x => x.uid === uid);
      console.log(this.sale)
      // this.sale = this.$store.getters.sales.find(x => x.uid === uid);
    }
  }

  get products(): Product[] {
    return this.$store.getters.products;
  }

  get startDate() {
    return this.sale.startDate;
  }

  set startDate(val: string) {
    this.sale.startDate = val;
  }

  get endDate() {
    return this.sale.endDate;
  }

  set endDate(val: string) {
    this.sale.endDate = val;
  }

  addProduct(p: Product) {
    console.info('adding product');
    let index = this.sale.products.findIndex(x => x === p.uid)
    if(index < 0) {
      this.sale.products.push(p.uid);
    }
  }

  removeProduct(p: Product) {
    console.info('removing product');
    let index = this.sale.products.findIndex(x => x === p.uid)
    if(index > -1) {
      this.sale.products.splice(index, 1);
    }
  }

  saveChanges() {
    console.log(this.sale);
    if(this.sale.uid === undefined || this.sale.uid === '') {
      this.sale.uid = md5(this.sale.name);
    }
    database().ref(`/sales/${this.sale.uid}`)
    .set(this.sale)
    .then((snapshot) => {
      console.log('Successfuly saved changes!')
    })
    .catch((error) => {
      console.log(error);
    })
  }
  
}
