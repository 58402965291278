import Vue from 'vue';
import Router from 'vue-router';
import Index from '../components/Index.vue';
import About from '../components/About.vue';
import Store from '../components/Store.vue';
import Product from '../components/Product.vue';
import EditProduct from '../components/EditProduct.vue';
import Checkout from '../components/Checkout.vue';
import Downloads from '../components/Downloads.vue';
import Dashboard from '../components/Dashboard.vue';
import EditEmailTemplate from '../components/EditEmailTemplate.vue';
import Unsubscribe from '../components/Unsubscribe.vue';
import Home from '../components/Home.vue';
import Blog from '../components/Blog/Blog.vue';
import EditPost from '../components/Blog/EditPost.vue';
import VersionManager from '../components/VersionManager.vue';
import ResetPassword from '../components/PasswordReset.vue';
import Support from '../components/Support.vue';
import News from '../components/News.vue';
import NewsItem from '../components/NewsItem.vue';
import EditNewsPost from '../components/EditNewsPost.vue';
import EditSale from '../components/EditSale.vue';
import firebase from 'firebase/app';
import SignUp from '../components/SignUp.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/news',
      name: 'news',
      component: News,
    },
    {
      path: '/auth',
      name: 'auth',
      component: SignUp
    },
    {
      path: '/news/:uid',
      name: 'news_item',
      component: NewsItem,
    },
    {
      path: '/edit_news_post',
      name: 'new_news_post',
      component: EditNewsPost,
      beforeEnter: (to, from, next) => {
        checkForAdmin(next, '404');
      },
    },
    {
      path: '/edit_news_post/:uid',
      name: 'edit_news_post',
      component: EditNewsPost,
      beforeEnter: (to, from, next) => {
        checkForAdmin(next, '404');
      },
    },
    {
      path: '/edit_sale',
      name: 'new_sale',
      component: EditSale,
      beforeEnter: (to, from, next) => {
        checkForAdmin(next, '404');
      },
    },
    {
      path: '/edit_sale/:uid',
      name: 'edit_sale',
      component: EditSale,
      beforeEnter: (to, from, next) => {
        checkForAdmin(next, '404');
      },
    },
    {
      path: '/store',
      name: 'store',
      component: Store,
    },
    {
      path: '/product/:name',
      name: 'product',
      component: Product,
    },
    {
      path: '/edit_product',
      name: 'new_product',
      component: EditProduct,
      beforeEnter: (to, from, next) => {
        checkForAdmin(next, '404');
      },
    },
    {
      path: '/edit_product/:name',
      name: 'edit_product',
      component: EditProduct,
      beforeEnter: (to, from, next) => {
        checkForAdmin(next, '404');
      },
    },
    {
      path: '/edit_email_template/:name',
      name: 'edit_email_template',
      component: EditEmailTemplate,
      beforeEnter: (to, from, next) => {
        checkForAdmin(next, '404');
      },
    },
    {
      path: '/edit_email_template',
      name: 'new_email_template',
      component: EditEmailTemplate,
      beforeEnter: (to, from, next) => {
        checkForAdmin(next, '404');
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: Checkout,
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: Downloads,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      // beforeEnter: (to, from, next) => {
      //   checkForAdmin(next, '404');
      // }
    },
    {
      path: '/versions/:product',
      name: 'versions',
      component: VersionManager,
    },
    {
      path: '/unsubscribe/:uid',
      name: 'unsubscribe',
      component: Unsubscribe,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/reset_password',
      name: 'reset_password',
      component: ResetPassword,
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

function checkForAdmin(next: any, toFailed: string) {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    // tslint:disable-next-line:no-empty
    firebase.database().ref(`/users/${currentUser.uid}/role`).once('value', (snapshot) => { }).then((x) => {
      if (x.val() === 'admin') {
        next();
      } else {
        next(toFailed);
      }
    });
  } else {
    next(toFailed);
  }
}

// router.beforeEach((to, from, next) => {
//   console.log(to);
//   next();
//   // if(to.path == '/')
//   //   next({name: 'store'});
//   // else
//   //   next();
// });

export default router;
