
























































import Vue from 'vue';
import { Database } from '../services/database';
import { Product, prettyVersion } from '../models/product';
import { GlobalService } from '../services/global-service';
import { database, auth } from 'firebase/app';
import DownloadDialogVue from './DownloadDialog.vue';
import md5 from 'md5';

const description = `Access downloads for your account.`;

export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - Downloads',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: description },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - Downloads' },
      { itemprop: 'description', content: description },
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FCTR_Icon2.png?alt=media&token=17d36c9e-1883-465c-933c-51053f8ec888' },
    ],
  },
  data() {
    return {
    };
  },
  methods: {
    track() {
      // this.$ga.page('/downloads');
    },
    extractTextFromHtml(s: string) {
      var span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    prettyVersion(product: Product) {
      if (product) {
        return prettyVersion(product);
      } else {
        return '';
      }
    },
  },
  computed: {
    downloads: {
      get(): Product[] {
        console.log(this.$store.getters.downloads);
        return this.$store.getters.downloads;
      },
    },
  },
  created() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        database().ref(`/downloads/${user.uid}`).on('child_changed', (snapshot) => { this.$store.dispatch('loadDownloadsFromFirebase'); });
      }
    });
  },
  components: {
    'v-download-dialog': DownloadDialogVue,
  },
});
