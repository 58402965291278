import md5 from 'md5';

export class GlobalService {
  public static ToArray(object: any) {
    const arr: any[] = [];
    Object.keys(object).forEach((key: string) => {
      arr.push(object[key]);
    });
    return arr;
  }

  public static ToHash(name: string) {
    return md5(name);
  }
}
