import { Product } from '@/models/product';
import { Sale } from '@/models/sale';
import { store } from '../store';

export class SaleService {
  public static SalePrice(product: Product): string {
    let discountPercent = 0;
    store.getters.sales.forEach((element: Sale) => {
      if(element.products.includes(product.uid)) {
        discountPercent += element.percentage;
      }
    });
    return (+product.price * (1 - discountPercent)).toFixed(2);
  }

  public static SalePriceAll(products: Product[]): string {
    let price = 0;
    products.forEach((product) => {
      price += +this.SalePrice(product);
    });
    return price.toFixed(2);
  }

  public static OnSale(product: Product): boolean {
    const sales: Sale[] = store.getters.sales;
    return sales.some((s) => s.products.includes(product.uid));
  }
}
