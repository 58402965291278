



























import Vue from 'vue'
import { Payment } from '@/models/payment';
import LineChart from './LineChart.vue';
export default Vue.extend({
  data() {
    return {
      headers: [
        { text: 'Date', value: 'date'},
        { text: 'Type', value: 'type'},
        { text: 'Amount', value: 'amount'},
        { text: 'Email'}
      ],
    }
  },
  computed: {
    payments: {
      get(): Payment[] {
        return this.$store.getters.payments;
      }
    },
    paymentsSorted: {
      get(): Payment[] {
        return this.payments.sort(function(a, b) {
          const adate = new Date(a.date);
          const bdate = new Date(b.date);
          return adate>bdate ? -1 : adate<bdate ? 1 : 0;
        });
      },
    },
    paymentsValues: {
      get(): number[] {
        const values: number[] = [];
        for(let i = 0; i < 12; ++i) {
          const year = new Date().getFullYear();
          const startDate = new Date(year, i, 1);
          const endDate = new Date(year, i + 1, 1);
          const valArray = this.payments
            .filter((p) => {
              const date = new Date(p.date);
              return date >= startDate && date < endDate;
            })
            .map((p) => p.amount);
          let val = 0;
          valArray.forEach(x => val += x);
          console.log(`month: ${i + 1}`);
          console.log(startDate);
          console.log(valArray);
          values.push(val);
        }
        const test = this.payments.map((value) => {
          return value.amount;
        });
        return values;
      }
    }
  },
  mounted() {
    this.$store.dispatch('loadPaymentsFromFirebase');
  },
  components: {
    'line-chart': LineChart,
  }
})
