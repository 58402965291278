


















































































import Vue from 'vue';
import { database, auth } from 'firebase/app';
// import { VueRecaptcha } from 'vue-recaptcha-v3';

export default Vue.extend({
  data() {
    return {
      visible: false,
      showCaptcha: false,
      email: '',
      password: '',
      confirmPassword: '',
      subscribeToEmails: true,
      message: '',
      alertMessage: '',
      isSignUpForm: false,
      verified: false,
      emailRules: [
        (v: any) => !!v || 'E-mail is required',
        (v: any) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [],
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.$store.state.showSignIn;
      },
      set(value: boolean) {
        this.$store.commit('showSignIn', value);
      },
    },
  },
  mounted() {
    (window as any).recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container');
    (window as any).recaptchaVerifier.render()
  },
  methods: {
    // async recaptcha() {

    // },
    track() {
      //this.$ga.page('/');
    },
    submit() {
      
      // (window as any).recaptchaVerifier.clear()
      this.handleRecaptchaResponse({});
      
    },
    handleRecaptchaResponse(response) {
      if (this.isSignUpForm) {
        let error = false;
          auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .then((userCredential) => {
            database()
              .ref(`/users/${userCredential.user.uid}`)
              .set({
                email: userCredential.user.email,
                subscribedToEmails: this.subscribeToEmails,
              })
              .then((result) => {
                // console.log(result);
              });
            this.$store.commit('showSignIn', false);
            this.$store.commit('showLoginConfirmation', true);
          })
          .catch((err) => {
            error = true;
            this.alertMessage = err.message;
          })
          .finally(() => {
            this.doRedirectAfterLogin(error);
          });
      } else {
          this.doEmailLogin();
      }
    },
    doEmailLogin() {
      let error = false;
      auth()
      .signInWithEmailAndPassword(this.email, this.password)
      .then((userCredential) => {
        this.$store.commit('showSignIn', false);
        this.$store.commit('showLoginConfirmation', true);
        this.showCaptcha = false;
        this.email = '';
        this.alertMessage = '';
        this.password = '';
      })
      .catch((err) => {
        error = true;
        if(err.code === "auth/too-many-requests") {
          this.showCaptcha = true;
          this.alertMessage = "Too many login attempts. Account is temporarily locked.";
          (window as any).recaptchaVerifier.reset();
          // (window as any).recaptchaVerifier.render();
        } else {
          this.alertMessage = "Username or password is not correct. Please try again.";
        }
      })
      .finally(() => {
        this.doRedirectAfterLogin(error);
        // (window as any).recaptchaVerifier.clear();
        // (window as any).recaptchaVerifier.render();
      });
    },
    doRedirectAfterLogin(error: boolean) {
      if(!error) {
        this.$router.push({name: 'downloads'})
      }
    },
    signInWithGoogle() {
      this.$store.commit('showSignIn', false);
      const provider = new auth.GoogleAuthProvider();
      auth()
        .signInWithPopup(provider)
        .then((result) => {
          const token = result.credential.providerId;
          const user = result.user;
          if (user) {
            this.$store.commit('showLoginConfirmation', true);
            database()
              .ref(`/users/${user.uid}`)
              .once('value', (snapshot) => {
                if (!snapshot.val()) {
                  database()
                    .ref(`/users/${user.uid}`)
                    .set({ email: user.email, subscribedToEmails: true });
                }
              });
          }
        })
        .catch((error) => {
          this.$store.commit('showSignIn', false);
          this.$store.commit('showLoginConfirmation', true);
        });
    },
    changeToSignIn() {
      this.isSignUpForm = false;
    },
    changeToSignUp() {
      this.isSignUpForm = true;
      this.showCaptcha = true;
    },
    nagivateToPasswordReset() {
      this.$store.commit('showSignIn', false);
      this.$router.push({ name: 'reset_password' });
    },
  },
  created() {
    this.visible = this.show;
  },
  name: 'v-signup-dialog',
});
