














import Vue from 'vue';
import { EmailTemplate } from '../models/emailTemplate';
import { GlobalService } from '../services/global-service';
import { database } from 'firebase/app';
export default Vue.extend({
  data() {
    return {
      emailTemplate: {
        name: '',
        subject: '',
        body: '',
      } as EmailTemplate,
    };
  },
  methods: {
    track() {
      //this.$ga.page('/');
    },
    saveEmailTemplate() {
      database().ref(`/emailTemplates/${this.emailTemplate.name}`).set(this.emailTemplate);
    },
    sendTestEmail() {
      database().ref(`/emails/test/${this.emailTemplate.name}`).set('send');
    },
  },
  created() {
    const nameFromPath = this.$route.params.name;
    if (nameFromPath) {
      database().ref(`/emailTemplates/${nameFromPath}`).on('value', (snapshot) => {
        this.emailTemplate = snapshot.val();
      });
    }
  },
});
