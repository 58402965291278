import {database, auth} from 'firebase/app';
import {Authentication} from './authentication';
import firebase from 'firebase/app';

export class PaymentService {

  public static paymentStatus() {
    const userDetails = auth().currentUser;
    if (userDetails != null) {
      return database().ref(`/payments/${userDetails.uid}`).once('value').then((snapshot) => {
        return snapshot.val() as string;
      });
    }
  }

  public static processPayment(token: any, amount: number, type: string, lineItems: [{name: string, amount: string}]) {
    firebase.analytics().logEvent('processPayment', {token, amount, type, lineItems});
    const userDetails = auth().currentUser;
    if (userDetails) {
      const payment = { token, amount, type, date: new Date().toISOString(), lineItems};
      firebase.analytics().logEvent('processPaymentPayment', payment);
      return database().ref(`/payments/${userDetails.uid}`).push(payment).then( (x) => {
        firebase.analytics().logEvent('pushPaymentToDB');
        return database().ref(`/cart/${userDetails.uid}/status`).set('processing');
      });
    }
  }
}
