import { SaleService } from './../services/sale-service';
import Vue from 'vue';
import Vuex from 'vuex';
import 'es6-promise/auto';
import { Product, Sort } from '../models/product';
import { database, auth } from 'firebase/app';
import { GlobalService } from '@/services/global-service';
import { stat } from 'fs';
import { NewsPost } from '@/models/newsPost';
import { Sale } from '@/models/sale';
import { Payment, excludeEmailList } from '@/models/payment';


Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    products: [] as Product[],
    productInView: {
      uid: '',
      name: '',
      brief_description: '',
      full_description: '',
      audioURLs: [],
      downloadURL: '',
      imageURLs: [],
      version: '',
      isLegacy: false,
      category: '',
      price: '',
      order: 0,
      videoPreview: '',
      bundleIds: [],
    } as Product,
    downloadIds: [],
    showSignIn: false,
    showLoginConfirmation: false,
    showCartWarningModal: false,
    showDownloadsWarningModal: false,
    cart: {
      productIds: [] as string[],
      total: 0,
    },
    payments: [],
    newsPosts: [] as NewsPost[],
    sales: [] as Sale[],
  },
  getters: {
    products: (state) => state.products,
    downloads: (state) => {
      const downloads = [];
      state.downloadIds.forEach((downloadId) => {
        const product = state.products.find((x) => x.uid === downloadId);
        if (product && product.category === 'Bundle') {
          product.bundleIds.forEach((bundleId) => {
            downloads.push(state.products.find((x) => x.uid === bundleId));
          });
        } else if (product) {
          downloads.push(product);
        }

      });
      return downloads;
    },
    cartLength: (state) => state.cart.productIds !== undefined ? state.cart.productIds.length : 0,
    cartTotal: (state) => {
      return SaleService.SalePriceAll(state.products.filter((x) => state.cart.productIds.includes(x.uid)));
    },
    cartItems: (state) => {
      return state.cart.productIds.length;
    },
    cartAsStripeProducts: (state) => {
      const products = [];
      state.cart.productIds.forEach((p) => {
        const product = state.products.find((x) => x.uid === p);
        products.push({
          name: product.name,
          description: product.full_description,
          amount: +product.price * 100,
        });
      });
    },
    payments: (state) => {
      return state.payments;
    },
    showLoginConfirmation: (state) => state.showLoginConfirmation,
    showCartWarningModal: (state) => state.showCartWarningModal,
    showDownloadsWarningModal: (state) => state.showDownloadsWarningModal,
    productInView: (state) => state.productInView,
    newsPosts: (state) => state.newsPosts,
    sales: (state) => {
      return state.sales.filter((sale) => {
        const start: Date = new Date(sale.startDate);
        const end: Date = new Date(sale.endDate);
        const current: Date = new Date();
        return current.getTime() >= start.getTime() && current.getTime() <= end.getTime(); 
      });
    },
    salesAll: (state) => state.sales,
  },
  mutations: {
    cart(state, productIds: string[]) {state.cart.productIds = productIds; },
    products(state, products: Product[]) { state.products = products; },
    addProduct: (state, product: Product) => {
      const currentProductIndex = state.products.findIndex((x) => x.uid === product.uid);
      if (currentProductIndex > -1) {
        state.products.splice(currentProductIndex, 1);
        state.products.push(product);
        state.products = Sort(state.products);
      } else {
        state.products.push(product);
      }
    },
    showSignIn(state, bool) { state.showSignIn = bool; },
    payments(state, payments) { state.payments = payments; },
    addToCart(state, productId: string) { state.cart.productIds.push(productId); },
    removeFromCart(state, productId: string) {
      const index = state.cart.productIds.findIndex((x) => x === productId);
      state.cart.productIds.splice(index, 1);
    },
    setCart(state, cart: any) { state.cart.productIds = cart; },
    setDownloads(state, downloadIds: string[]) { state.downloadIds = downloadIds; },
    showLoginConfirmation: (state, value: boolean) => state.showLoginConfirmation = value,
    showCartWarningModal: (state, value: boolean) => state.showCartWarningModal = value,
    showDownloadsWarningModal: (state, value: boolean) => state.showDownloadsWarningModal = value,
    setProductInView: (state, productId: string) => {
      const product = state.products.find((x) => x.uid === productId);
      if (product) {
        state.productInView = product;
      }
    },
    setNewsPosts: (state, newsPosts: NewsPost[]) => state.newsPosts = newsPosts,
    sales: (state, sales: Sale[]) => state.sales = sales,
  },
  actions: {
    addToCart(context, productId: string) {
      let found = false;
      context.state.cart.productIds.forEach((pid) => {
        if (pid === productId) {
          context.commit('showCartWarningModal', true);
          found = true;
        }
        const product = context.state.products.find((x) => x.uid === pid);
        if (product && product.category === 'Bundle') {
          product.bundleIds.forEach((bundleId) => {
            if (bundleId === productId) {
              context.commit('showCartWarningModal', true);
              found = true;
            }
          });
        }
      });


      context.state.downloadIds.forEach((pid) => {
        if (pid === productId) {
          context.commit('showDownloadsWarningModal', true);
          found = true;
        }
        const product = context.state.products.find((x) => x.uid === pid);
        if (product && product.category === 'Bundle') {
          product.bundleIds.forEach((bundleId) => {
            if (bundleId === productId) {
              context.commit('showDownloadsWarningModal', true);
              found = true;
            }
          });
        }
      });

      if (found) { return; } else {
        database().ref(`/cart/${auth().currentUser.uid}/products/${productId}`).set(productId).then(() => {
          context.commit('addToCart', productId);
        }, () => {
          console.log('failed to add product id to users cart');
        });
      }
    },
    removeFromCart(context, productId: string) {
      database().ref(`/cart/${auth().currentUser.uid}/products/${productId}`).remove().then(
        () => {
          context.commit('removeFromCart', productId);
        },
        () => {
          console.warn('failed to remove product from cart');
        },
      );
    },
    loadCartFromFirebase: (context) => {
      if (auth().currentUser) {
        database().ref(`/cart/${auth().currentUser.uid}/products`).once('value', (snapshot) => {
          const cart = snapshot.val();
          if (cart) {
            context.commit('setCart', GlobalService.ToArray(cart));
          }
        });
      }
    },
    loadDownloadsFromFirebase: (context) => {
      if (auth().currentUser) {
        database().ref(`/downloads/${auth().currentUser.uid}`).on('value', (snapshot) => {
          const downloadIds = snapshot.val();
          if (downloadIds) {
            context.commit('setDownloads', GlobalService.ToArray(downloadIds));
          }
        });
      }
    },
    loadSingleProductFromFirebase: (context, uid) => {
      return database().ref(`/products/${uid}`).once('value', (snapshot) => {
        const product = snapshot.val() as Product;
        if (product) {
          product.imageURLs = GlobalService.ToArray(product.imageURLs);
          context.commit('addProduct', product);
          context.commit('setProductInView', uid);
        }
      });
    },
    loadNewsPostsFromFirebase: (context) => {
      return database().ref(`/news`).once('value', (snapshot) => {
        const newsPostsMap = snapshot.val();
        const newsPosts = [] as NewsPost[];
        // console.log(newsPosts);
        if (newsPosts) {
          Object.keys(newsPostsMap).forEach((key) => newsPosts.push(newsPostsMap[key]));
          newsPosts.sort(function(a, b) {
            const aDate = new Date(a.createDate);
            const bDate = new Date(b.createDate);
            return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
          });
          context.commit('setNewsPosts', newsPosts);
        }
      });
    },
    loadSalesFromFirebase: (context) => {
      return database().ref(`/sales`).once('value', (snapshot) => {
        const sales = snapshot.val();
        const salesArr = [];
        Object.keys(sales).forEach((key) => {
          salesArr.push(sales[key]);
        })
        context.commit('sales', salesArr);
      });
    },
    loadPaymentsFromFirebase: (context) => {
      let payments: Payment[] = [];
      return database().ref('/payments').once('value', (snapshot) => {
        Object.keys(snapshot.val()).forEach((user) => {
          const uid = user;
          database().ref(`/payments/${uid}`).once('value', (snap) => {
            Object.keys(snap.val()).forEach((key) => {
              const value: Payment = snap.val()[key];
              if (+value.amount > 0 && 
                  ( (value.type === 'paypal' && !excludeEmailList.includes(value.token.payer.payer_info.email)) || (value.type ==='stripe' && !excludeEmailList.includes(value.token.email)))
               ) {
                payments.push(value);
                // this.pushPaymentToGraph(value);
              }
            });
          });
        });
        payments = payments.sort((a, b) => {
          const adate = new Date(a.date);
          const bdate = new Date(b.date);
          return a > b ? -1 : adate < bdate ? 1 : 0;
        });
        // this.values = this.values.sort((a, b) => {
        //   a = new Date(a[0]);
        //   b = new Date(b[0]);
        //   return a > b ? -1 : a < b ? 1 : 0;
        // });
        context.commit('payments', payments);
      });
    }
  },
});
export {store};
