




















import Vue from 'vue';
import { NewsPost } from '../models/newsPost';
export default Vue.extend({
  data() {
    return {

    };
  },
  methods: {
    getFormattedDate(newsPost: NewsPost) {
      if (newsPost.createDate) {
        return new Date(newsPost.createDate).toDateString();
      } else {
        return '';
      }
    },
    openNewsPost(uid: string) {
      this.$router.push(`/news/${uid}`);
    },
    getBriefContent(content) {
      const length = 200;
      // tslint:disable-next-line:max-line-length
      let briefContent = content.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, ' ').substring(0, length - 3);
      briefContent = briefContent.substring(0, briefContent.lastIndexOf(' '));
      briefContent += '...';
      return briefContent;
    },
  },
  computed: {
    newsPosts: {
      get(): NewsPost[] {
        return this.$store.getters.newsPosts;
      },
    },
  },
  created() {
  },
});
