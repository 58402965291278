
















import Vue from 'vue';
import { database } from 'firebase/app';

export default Vue.extend({
  data() {
    return {
      emailAddress: '' as string,
      notFound: false as boolean,
      loading: true as boolean,
    };
  },
  methods: {
    track() {
      //this.$ga.page('/');
    },
  },
  created() {
    database().ref(`/users/${this.$route.params.uid}`).on('value', (snapshot) => {
      const user = snapshot.val();
      if (user) {
        this.emailAddress = snapshot.child('email').val();
        database().ref(`/users/${this.$route.params.uid}/subscribedToEmails`).set(false);
      } else {
        this.notFound = true;
      }

      this.loading = false;

    });

  },
});
