


















































































































import Vue from 'vue';
import { Product } from '../models/product';
import { database, storage } from 'firebase/app';
import md5 from 'md5';
import AudioPlayerVue from './AudioPlayer.vue';
import { Upload } from '../models/product';
import { VueEditor } from 'vue2-editor';
import { StorageService } from '../services/storage';

export default Vue.extend({
  data() {
    return {
      html: '' as string,
      productKey: '' as string,
      audioFiles: [] as Upload[],
      bundleId: '',
      product: {
        name: '',
        full_description: '',
        videoPreview: '',
        imageURLs: [],
        downloadURL: '',
        audioURLs: [],
        order: 0,
        category: '',
        bundleIds: []
      } as Product,
      possibleCategories: [
        "Plugin",
        "Samples",
        "Bundle"
      ]
    };
  },
  props: {},
  methods: {
    track() {
      //this.$ga.page('/');
    },
    saveButtonClick() {
      this.productKey === '' || this.productKey === undefined
        ? this.createProduct()
        : this.updateProduct();
    },
    addToBundleIds() {
      if(this.product.bundleIds === undefined)
        this.product.bundleIds = []
      this.product.bundleIds.push(this.bundleId);
      console.log(this.product.bundleIds);
    },
    removeBundleId(bundleId: string) {
      const index = this.product.bundleIds.findIndex(x => x === bundleId);
      this.product.bundleIds.splice(index, 1);
    },
    createProduct() {
      console.log('creating new product');
      const hash = md5(this.product.name);
      console.log('hash = ' + hash);
      const ref = database().ref(`/products/${hash}`);
      ref.set(this.product);
      ref.child('uid').set(hash);
    },
    updateProduct() {
      console.log('updating existing product');
      if (this.productKey !== '') {
        database()
          .ref(`/products/${this.productKey}`)
          .set(this.product);
      }
    },
    downloadFilesSelected(name: string, files: File[]) {
      Array.from(files).forEach((file) => {
        const storageRef = storage().ref(
          `/audio/${this.productKey}/${file.name}`,
        );
        storageRef.put(file).then((x) => {
          storageRef.getDownloadURL().then((url) => {
            const upload: Upload = { name: file.name, url, order: 0 };
            database()
              .ref(`/products/${this.productKey}/audioURLs`)
              .push(upload);
          });
        });
      });
    },
    filesChange(name: string, files: File[]) {
      Array.from(files).forEach((file) => {
        const storageRef = storage().ref(
          `products/${this.productKey}/${file.name}`,
        );
        storageRef.put(file).then((x) => {
          storageRef.getDownloadURL().then((url) => {
            const upload: Upload = { name: file.name, url, order: 0 };
            database()
              .ref(`/products/${this.productKey}/imageURLs`)
              .push(upload);
          });
        });
      });
    },
    zipFileSelected(name: string, files: File[]) {
      const file = files[0];
      const storageRef = storage().ref(
        `products/${this.productKey}/${file.name}`,
      );
      storageRef.put(file).then((x) => {
        storageRef.getDownloadURL().then((url) => {
          const upload: Upload = { name: file.name, url, order: 0 };
          database()
            .ref(`/products/${this.productKey}/downloadURL`)
            .set(upload);
        });
      });
    },
    handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: any) {
      StorageService.upload(file, 'post_images').then((url) => {
        Editor.insertEmbed(cursorLocation, 'image', url);
      });
    },
  },
  created() {
    const name = this.$route.params.name;
    if (name !== undefined) {
      database()
        .ref('/products')
        .on('value', (snapshot) => {
          const products = snapshot.val();
          Object.keys(products).forEach((key) => {
            const p = products[key];
            if (p.name === name) {
              this.product = p as Product;
              this.productKey = key;
            }
          });
        });
    }
  },
  components: {
    VueEditor,
    'v-audio-player': AudioPlayerVue,
  },
});
