import { database, auth } from 'firebase/app';
import Vue from 'vue';
import { Product } from '../models/product';
import { Authentication } from './authentication';
import { GlobalService } from './global-service';

export class Database {
  public static getProducts(): Promise<Product[]> {
    const vm = this;
    const p = new Array<Product>();
    const ref = database().ref('products');
    return ref.once('value')
    .then((snapshot) => {
      const products = snapshot.val();
      Object.keys(products).forEach((key) => {
        p.push(products[key] as Product);
      });
      return p;
    });
  }

  public static addToCart(product: Product) {
    if (product.name && product.price && product.imageURLs) {
      let found = false;
      const uid = auth().currentUser.uid;
      const hash = GlobalService.ToHash(product.name);
      database().ref(`/cart/${uid}/products`).once('value', (snap) => {
        const products = snap.val();
        if (products != null && products !== undefined) {
          Object.keys(products).forEach((key) => {
            if (products[key].name === product.name) {
              found = true;
            }
          });
        }
        if (!found) {
          database().ref(`/cart/${uid}/products/${hash}`).set(product);
        }
      });
    }
  }

  public static cartRef() {
    const userDetails = auth().currentUser;
    return database().ref(`/cart/${userDetails.uid}/products`);
  }

  public static paypalPaymentComplete() {
    const userDetails = auth().currentUser;
    if (userDetails != null) {
      database().ref(`/cart/${userDetails.uid}/products`).once('value').then((snapshot) => {
        const products = snapshot.val() as Product[];
        database().ref(`/downloads/${userDetails.uid}`).set(products);
      }).then((x) => {
        database().ref(`/cart/${userDetails.uid}/products`).remove();
      });
    }
  }

  public static downloadsForUser(): Promise<Product[]> {
    const userDetails = auth().currentUser;
    if (userDetails != null) {
      return database().ref(`/downloads/${userDetails.uid}`).once('value').then((snapshot) => {
        return snapshot.val() as Product[];
      });
    } else {
      return new Promise(((resolve) => resolve(undefined as Product[])));
    }
  }
}
