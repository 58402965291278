
import { Vue, Component, Watch } from 'vue-property-decorator';
import VueChartJs from 'vue-chartjs';
import { Bar, Line, mixins } from 'vue-chartjs';

@Component({
  extends: Line,
})
export default class extends Vue  {
  public renderChart: any;

  public getMonth(date: Date) {
    const d = new Date(date);
    return d.toLocaleString('en-us', { month: 'long' });
  }


  get payments() {
    return this.$store.state.payments;
  }

  @Watch('payments')
  public onChildChanged(val: any[], oldVal: any[]) {
    const labels: string[] = [];
    const values: number[] = [];

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getUTCFullYear();
    for (let i = currentMonth - 6; i <= currentMonth; ++i) {
      const monthNumber = i < 0 ? 12 + i : i;
      labels.push(this.getMonth(new Date(i < 0 ? currentYear - 1 : currentYear, monthNumber)));
      let total = 0;
      val.filter((payment) => {
        const date = new Date(payment.date);
        const month = date.getMonth();
        const year = date.getUTCFullYear();
        return month === monthNumber && (year === currentYear || year === currentYear - 1);
      }).forEach((payment) => {
        total += payment.amount / 100;
      });
      values.push(total);
    }
    this.renderChart({
      labels,
      datasets: [
        {
          label: 'Total Revenue',
          backgroundColor: '#1565c0',
          data: values,
        },
      ],
    }, {responsive: true, maintainAspectRatio: false});
  }
}
