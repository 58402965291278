
















import Vue from 'vue'
import {NewsPost} from '../models/newsPost';
export default Vue.extend({
  data() {
    return {
      uid: ''
    }
  },
  computed: {
    newsPost: {
      get(): NewsPost {
        return this.$store.getters.newsPosts.find((x) => x.uid === this.uid);
      }
    }
  },
  created() {
    const uid = this.$route.params.uid;
    this.uid = uid;
    console.log(uid);
  }
});
