// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import AudioPlayer from './components/AudioPlayer.vue';
import router from './router';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import Axios from 'axios';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';

import {store} from './store';
import VueStripeCheckout from 'vue-stripe-checkout';
import 'vuetify/dist/vuetify.min.css';
import VueGraph from 'vue-graph';
import VueAnalytics from 'vue-analytics';
import Meta from 'vue-meta';

// import { VueReCaptcha } from 'vue-recaptcha-v3'



require('./assets/ctricon.png');

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.info = () => {};
  console.error = () => {};
}

// Vue.use(VueReCaptcha, { siteKey: '6LdU7wYbAAAAABNv1GIfVhFQ043Zuj8soBhjlw5s' })

Vue.use(Meta);

Vue.use(VueAnalytics, {
  id: 'UA-125151967-1',
  router,
});


Vue.use(VueGraph);
const testProd = true;
const config = process.env.NODE_ENV === 'production' || testProd ? {
  apiKey: 'AIzaSyAxcAqwX_X0jupwxl6cRxBPtdEbLNZRM6Q',
  authDomain: 'ctrwebsite-db7d9.firebaseapp.com',
  databaseURL: 'https://ctrwebsite-db7d9.firebaseio.com',
  projectId: 'ctrwebsite-db7d9',
  storageBucket: 'ctrwebsite-db7d9.appspot.com',
  messagingSenderId: '964197501530',
  appId: "1:964197501530:web:10033977e233a65e85e663",
  measurementId: 'UA-125151967-1'
} :
{
  apiKey: 'AIzaSyCH9hj8jdiuGgvcH3QXSXGKr6vfADqRdS0',
  authDomain: 'cut-through-recordings-dev.firebaseapp.com',
  databaseURL: 'https://cut-through-recordings-dev.firebaseio.com',
  projectId: 'cut-through-recordings-dev',
  storageBucket: 'cut-through-recordings-dev.appspot.com',
  messagingSenderId: '688505033566',
  appId: "1:688505033566:web:a11bbb59e6d43dc5b2b5cf",
  measurementId: 'UA-125151967-1'
};

firebase.initializeApp(config);
firebase.analytics();

const vuetifyOpts = {
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#020024',
        secondary: '#7900c4',
        accent: '#090979',
        error: '#b71c1c',
      },
      light: {
        primary: '#020024',
        secondary: '#7900c4',
        accent: '#090979',
        error: '#b71c1c',
      }
    }
  }
}
Vue.use(Vuetify);

const stripeOptions = {
  key: process.env.VUE_APP_STRIPE_KEY,
  // tslint:disable-next-line:max-line-length
  image: 'https://cutthroughrecordings.com/img/ctricon.bf2d200a.png',
  locale: 'auto',
  currency: 'usd',
  billingAddress: false,
  panelLabel: 'Pay {{amount}}',
};

Vue.use(VueStripeCheckout, stripeOptions);

Vue.config.productionTip = false;

// tslint:disable-next-line:no-unused-expression
new Vue({
  el: '#app',
  router,
  components: {
    App,
    'v-audio-player': AudioPlayer,
  },
  template: '<App/>',
  store,
  vuetify: new Vuetify(vuetifyOpts),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
});

Vue.prototype.$analytics = firebase.analytics();

window.onerror = function logerror(err) {
  firebase.analytics().logEvent('onerror', {error: JSON.stringify(err)})
}

Vue.config.errorHandler = function (err: Error, vm, info) {
  firebase.analytics().logEvent('vueErrorHandler', {error: JSON.stringify(err)})
}

window.addEventListener('unhandledrejection', function(err) {
  firebase.analytics().logEvent('unhandledRejection', {error: JSON.stringify(err)})
});


