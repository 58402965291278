












































import Vue from 'vue';
import { Product } from '../models/product';
import { auth } from 'firebase/app';
import { SaleService } from '../services/sale-service';
import firebase from 'firebase/app';

// tslint:disable-next-line:max-line-length
const description = 'Products offered by Cut Through Recordings include Multiband Compressors, Stereo Delays, Clippers, sound samples, and much more.  Check out our various free plugins as well.  Available for Windows, MacOS, and Linux!';
export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - Store',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: description },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - Store' },
      { itemprop: 'description', content: description },
      // tslint:disable-next-line:max-line-length
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FCTR_Icon2.png?alt=media&token=17d36c9e-1883-465c-933c-51053f8ec888' },
    ],
  },
  data() {
    return {
      loggedIn: false,
      cardHeight: 550,
    };
  },
  computed: {
    products(): Product[] {
      return this.$store.state.products.filter(x => !x.discontinued);
    },
  },
  methods: {
    track() {
      //this.$ga.page('/');
    },
    onSale(product: Product) {
      return SaleService.OnSale(product);
    },
    getBriefDescription(product: Product) {
      const length = 200;
      // tslint:disable-next-line:max-line-length
      let briefDescripton = product.full_description.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, ' ').substring(0, length - 3);
      briefDescripton = briefDescripton.substring(0, briefDescripton.lastIndexOf(' '));
      briefDescripton += '...';
      return briefDescripton;
    },
    getPrice(product: Product) {
      if (+product.price === 0) {
        return 'Free';
      } else {
        return '$' + product.price;
      }
    },
    salePrice(product: Product): string {
      return SaleService.SalePrice(product);
    },
    getDisplayImage(product: Product) {
      if (product.imageURLs.length) {
        const key: string = Object.keys(product.imageURLs)[0];
        if (product.imageURLs[key]) {
          return product.imageURLs[key].url;
        }
      }
    },
    openProductPage(product: Product) {
      this.$router.push({ name: 'product', params: { name: product.name.replace(/ /g, '_') } });
    },
    addToCart(product: Product) {
      if (this.loggedIn && product.name !== '') {
        firebase.analytics().logEvent('addToCart', {user: auth().currentUser, product});
        this.$store.dispatch('addToCart', product.uid);
      } else {
        this.$store.commit('showSignIn', true);
      }
    },
    submit() {
      this.$store.commit('showSignIn', false);
    },
  },
  created() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }

    });
  },
});
