




















import Vue from 'vue';
import Component from 'vue-class-component';
import { auth } from 'firebase/app';
import firebase from 'firebase/app';

@Component({
  components: {},
  props: {},
})
export default class extends Vue {
  public emailAddress = '';
  public submitted = false;
  public sendResetEmail() {
    // firebase.analytics().logEvent('sendPasswordResetEmail', {email: this.emailAddress});
    auth()
      .sendPasswordResetEmail(this.emailAddress)
      .then(() => {
        console.log(`Reset password email sent to ${this.emailAddress}`);
        this.submitted = true;
        firebase.analytics().logEvent('sendPasswordResetEmailSuccess');
      })
      .catch((error) => {
        console.log(error);
        firebase.analytics().logEvent('sendPasswordResetEmailError');
      });
  }
}
