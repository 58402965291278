
















































































import Vue from 'vue';
import { PaymentService } from '../services/payment-service';
import { SaleService } from '../services/sale-service';
import PayPal from 'vue-paypal-checkout';
import { Database } from '../services/database';
import { Product } from '../models/product';
import { database, auth } from 'firebase/app';
import { GlobalService } from '../services/global-service';
import firebase from 'firebase/app';

export default Vue.extend({
  data() {
    return {
      stripeKey: 'pk_test_sb2LRZKyFkKHd5Dcxug7SxHy',
      product: {
      },
      paypal: {
        style: {
          label: 'checkout',
          size: 'small',    // small | medium | large | responsive
          shape: 'rect',         // pill | rect
          color: 'blue',         // gold | blue | silver | black
        },
        credentials: {
          sandbox: process.env.VUE_APP_PAYPAL_KEY,
          production: process.env.VUE_APP_PAYPAL_KEY,
        },
      },
      processing: false as boolean,
    };
  },
  computed: {
    getEnv: {
      get() {
        const mode = process.env.NODE_ENV;
        return mode === 'production' ? 'production' : 'sandbox';
      },
    },
    products: {
      get() {
        const products = [];
        this.$store.state.cart.productIds.forEach((productId) => {
          products.push(this.$store.state.products.find((x) => x.uid === productId));
        });
        return products.filter(x => x !== undefined);
      },
    },
    cartTotal: {
      get(): any {
        return this.$store.getters.cartTotal;
      },
    },
    paypalItemsList() {
      const items = [];
      this.products.forEach((product) => {
        if(product.price > 0) {
          items.push({
          name: product.name,
          description: "",
          quantity: "1",
          price: SaleService.SalePrice(product).toString(),
          currency: "USD"
        });
        }
      })
      return items;
    },
  },
  methods: {
    onSale(product: Product) {
      return SaleService.OnSale(product);
    },
    salePrice(product: Product) {
      return SaleService.SalePrice(product);
    },
    track() {
      //this.$ga.page('/');
    },
    checkoutFreeItems() {
      firebase.analytics().logEvent('checkout_free', this.paypalItemsList);
      const currentUser = auth().currentUser;
      if (currentUser !== undefined) {
        database().ref(`/users/${currentUser.uid}/email`).once('value', (snapshot) => {
          const email = snapshot.val();
          if (email) {
            PaymentService.processPayment({ email }, 0, 'free', this.paypalItemsList as [{name: string, amount: string}]).then((x) => {
              this.$store.commit('cart', []);
              this.$router.push({ name: 'downloads' });
            });
          }
        });
      }
    },
    removeFromCart(product: Product) {
      if (auth().currentUser !== undefined) {
        this.$store.dispatch('removeFromCart', product.uid);
      }
    },
    getDisplayImage(product: Product) {
      const key = +Object.keys(product.imageURLs)[0];
      return product.imageURLs[key];
    },
    paypalCompleted(event: any) {
      firebase.analytics().logEvent('paypal_completed', event);
      if (event.state === 'approved') {
        PaymentService.processPayment(event, this.cartTotal * 100, 'paypal', this.paypalItemsList as [{name: string, amount: string}]).then((x) => {
          this.$store.commit('cart', []);
          this.$router.push({ name: 'downloads' });
        });

      }
    },

    paypalCheckoutClicked() {
      firebase.analytics().logEvent('paypalCheckoutOpened');
    },

    checkout() {
      firebase.analytics().logEvent('stripeCheckoutOpened');
      this.$checkout.open({
        name: 'Cut Through Recordings',
        email: 'ethanepr@hotmailcom',
        currency: 'USD',
        amount: this.cartTotal * 100,
        token: (token: any) => {
          PaymentService.processPayment(token, this.cartTotal * 100, 'stripe', this.paypalItemsList as [{name: string, amount: string}]).then((x) => {
            this.$store.commit('cart', []);
            this.$router.push({ name: 'downloads' });
          });
        },
      });
    },
    filterProducts(products: Product[]): Product[] {
      const filtered = new Array<Product>();
      Object.keys(this.products).forEach((key) => {
        const product = this.products[+key];
        if (
          product.name !== '' && product.name !== undefined &&
          product.price !== '' && product.price !== undefined) {
          filtered.push(product);
        }
      });
      return filtered;
    },
    strip(html) {
      return html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, ' ').replace(/&nbsp;/g, ' ');
    },
  },
  created() {
  },
  components: {
    PayPal,
  },
});
