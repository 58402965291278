import { MetaInfo } from 'vue-meta';

interface productMetadata {
    name: string;
    description: string;
    imageUrl: string;
}

const productMetaDatas = [
    {
        name: 'Convergence',
        description: 'Introducing the ultimate mastering tool.  This versatile mastering compressor can handle even the most dynamic of tracks.  Convergence features up to 10 bands of compression and as low as 2 bands.',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F8645457c64703c956325a6f44824acdb%2FMain5Bands.png?alt=media&token=b76552bd-346d-4aed-8277-19ae98dab8c4'
    },
    {
        name: 'M4 Multiband Compressor',
        description: 'M4 Multiband Compressor is designed to be the ultimate mixing and mastering tool. This compressor has 3 user-controlled crossovers resulting in 4 bands that can be as wide or as narrow as you desire.',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F2fab2a831b096bfd4c9acc8402464b78%2Fv2-0-1.png?alt=media&token=832d6f93-f323-4984-8d7f-8c7e801d4180'
    },
    {
        name: 'Entropy II - Enhanced Stereo Delay',
        description: `Entropy II, like it's predecessor, is a stereo crossover delay but with several features that will help take your recordings to the next level! Entropy II comes with many new features that will allow you to truly express your creative vision.`,
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F09f8a5f3b47d58762f99b234c6671330%2Fentropy2-screenshot2.png?alt=media&token=a91e9d47-15fd-404f-827a-1af41524b2db'
    },
    {
        name: 'EpicPRESS',
        description: 'Introducing EpicPRESS from Cut Through Recordings!  EpicPRESS is designed to be as flexible as possible, giving you full control over your dynamics.  This plug-in features stereo-linked compressors to give a smooth and even compression across both channels, as well as automatic make-up gain.',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F5d70657ea0c27920e6c2e8a81fc836fc%2FepicComp-screenshot4.png?alt=media&token=f558138b-df2e-400b-b019-f772af76e84d'
    },
    {
        name: 'Faradelay',
        description: 'Why have 3 separate modulated delay plug-ins when you can have one plug-in that does it all? The Chorus, Phaser, and Tremolo are created from the same effect called a modulated delay. Those effects are created by using slight variations for different parameters inside the modulated delay.',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F5f5a0d22d046f1fc49342885fd2ea0d0%2Ffaradelay_screenshot1.png?alt=media&token=e9a469e3-a4e8-4bcb-8962-6f154de8241a'
    },
    {
        name: 'All Plugins Bundle',
        description: 'All plugins in one convenient bundle! Save 20% by buying all of our plug-ins together! Take your mix to the next level without breaking the bank!',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F7618318a73af136f1ca2ca9e6ff69901%2FAllPluginsBundle-April2020.png?alt=media&token=fe27b3ad-7bab-4b64-a576-be3c0ef17872'
    },
    {
        name: 'Entropy - Stereo Cross-over Delay',
        description: `Entropy by CTR is a one of a kind delay plugin!  This delay has complete control over delay in each channel separately, but that isn't what makes this plugin special.  Entropy is a stereo cross-over delay.  This is a unique type of delay that delays the signal from the left channel and places it in the right, and delays the signal in the right channel and places it in the left.`,
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F90a5f8589591e344f60ff0e9b3a64d68%2Fentropy.png?alt=media&token=e1403a63-bc58-4de1-82a0-836f9f706cc1'
    },
    {
        name: 'CTR Custom Bass Drops',
        description: '45 Unique Bass Drops Ranging from A(55hz) to C#(69hz) each with 3 lengths and 3 levels of saturation! The only bass drops you should ever need.  Each bass drop has been hand crafted to ensure that it will cut through the mix and deliver that powerful punch when you need to knock your listeners back.',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2Fc61c9502f313771679d11ab67102ead3%2FCustomBassDropsThumb.png?alt=media&token=819201ad-f9cd-4a16-862d-1f38e7910dd8'
    }
] as productMetadata[]

export function productNameToMetaInfo(productName: string, routeFullPath): MetaInfo {
    const productMeta = productMetaDatas.find(x => x.name === productName);
    return productMeta !== undefined ? buildProductMetaInfo(productMeta.name, routeFullPath, productMeta.description, productMeta.imageUrl) : buildProductMetaInfo('', routeFullPath, '', '');
}

function buildProductMetaInfo(productName: string, routeFullPath: string, description: string, imageUrl: string) {
    return {
        title: `Cut Through Recordings - ${productName}`,
        meta: [
            { vmid: 'description', name: 'description', content: description },
            {property: 'og:title', content: `Cut Through Recordings - ${productName}`},
            {property: 'og:site_name', content: `Cut Through Recordings - ${productName}`},
            {property: 'og:type', content: 'website'},
            {property: 'og:url', content: `https://cutthroughrecordings.com${routeFullPath}`},
            {property: 'og:image', content: imageUrl},
            {property: 'og:description', content: description}
        ],
    }
}
