












import Vue from 'vue';
import { Howl } from 'howler';
import { interval } from 'rxjs';
export default Vue.extend({
  props: {
    url: {
      default: '',
    },
    name: {
      default: '',
    },
  },
  data() {
    return {
      audio: undefined as any,
      isPlaying: false as boolean,
      progress_bar_value: 0 as number,
    };
  },
  methods: {
    play() {
      this.isPlaying = true;
      if (!this.audio.playing()) {
        this.audio.play();
      }
    },

    stop() {
      this.isPlaying = false;
      this.audio.stop();
    },

    pause() {
      this.audio.pause();
      this.isPlaying = false;
      // console.log('Seek Position');
      // console.log(this.audio.duration());
      // console.log(this.audio.seek());
    },
  },
  created() {
    // console.log(this.url);
    this.audio = new Howl({
      src: [this.url],
      onend: () => {
        this.isPlaying = false;
      },
    });

    interval(20).subscribe((x) => {
      if (this.audio.playing) {
        this.progress_bar_value = (this.audio.seek() as number / this.audio.duration()) * 100;
      } else {
        this.isPlaying = false;
      }
    });

  },
  destroyed() {
    this.stop();
  },
});
