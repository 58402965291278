










































import Vue from 'vue';
import { database, storage } from 'firebase/app';
import md5 from 'md5';
import firebase from 'firebase/app';
import { Product } from '../models/product';

interface PluginType {
  name: string;
  desc: string;
}

export default Vue.extend({
  data() {
    return {
      showDownloadModal: false as boolean,
      selectedOS: 'Windows',
      availableOS: ['Windows', 'Mac OS', 'Linux'],
      selectedPluginType: {name: 'VST', desc: 'VST'},
      selectedArchitecture: '64 bit' as string,

    };
  },
  props: [
    'productName',
    'product',
    'isDemo',
  ],
  computed: {
    availablePluginTypes: {
      get() {
        const pluginTypes: PluginType[] = [];
        if (this.selectedOS === 'Mac OS') {
          if(this.product.installer) {
            pluginTypes.push({name: 'Installer', desc: 'Installer (Includes AU, VST, and VST3)'});
          } else {
            if (this.product.vst) {
              pluginTypes.push({name: 'VST', desc: 'VST'});
            }
            if (this.product.vst3) {
              pluginTypes.push({name: 'VST3', desc: 'VST3'});
            }
            if (this.product.au) {
              pluginTypes.push({name: 'AU', desc: 'Audio Unit v2'})
            }
          }
        }
        else if(this.selectedOS === 'Windows'){
          if(this.product.installer) {
            pluginTypes.push({name: 'Installer', desc: 'Installer (Includes VST, VST3, and AAX'});
          } else {
            if (this.product.vst) {
              pluginTypes.push({name: 'VST', desc: 'VST'});
            }
            if (this.product.vst3) {
              pluginTypes.push({name: 'VST3', desc: 'VST3'});
            }
            if (this.product.aax) {
              pluginTypes.push({name: 'AAX', desc: 'AAX'})
            }
          }
        }
        else if(this.selectedOS === 'Linux') {
          if (this.product.vst) {
            pluginTypes.push({name: 'VST', desc: 'VST'});
          }
          if (this.product.vst3) {
            pluginTypes.push({name: 'VST3', desc: 'VST3'});
          }
          if (this.product.lv2) {
            pluginTypes.push({name: 'LV2', desc: 'LV2'})
          }
        }
        return pluginTypes;
      },
    },
    availableArchitectures: {
      get() {
        // if(this.selectedOS === 'Linux' || (this.selectedOS === 'Windows' && this.selectedPluginType.name === 'LV2'))
        return ['64 bit'];
        // return ['64 bit', '32 bit'];
      }
    }
  },
  methods:
    {
      osChanged() {
        if (!this.availablePluginTypes.some(x => x.name === this.selectedPluginType.name)) {
          this.selectedPluginType = this.availablePluginTypes[0];
        }

        if(this.selectedOS === 'Windows' || this.selectedOS === 'Mac OS') {
          this.selectedArchitecture = '64 bit';
        }
      },
      downloadFromFirebaseStorage(path: string, fileName: string) {
        storage().ref(path).getDownloadURL().then(function(downloadURL) {
          const element = document.createElement('a');
          element.setAttribute('href', downloadURL);
          element.setAttribute('download', fileName + '.zip');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }).catch(function(error) {
          console.warn(error);
        });

      },
      getTypeFromPluginType() {
        return this.isDemo ? `${this.selectedPluginType.name}-Demo` : this.selectedPluginType.name;
      },
      downloadDemo() {
        const hash = md5(this.productName);
        const arch = this.selectedArchitecture === '64 bit' ? 'x86_64' : 'x86';
        const os = this.selectedOS === 'Windows' ? 'win' : this.selectedOS === 'Mac OS' ? 'mac' : 'linux';
        const type = this.getTypeFromPluginType();
        const refPath = `/builds/${hash}/${this.product.version}/${os}/${type}/${arch}`;
        const databaseRef = database().ref(refPath);
        firebase.analytics().logEvent('downloadPlugin', {hash, arch, os, type, refPath, product: this.productName})
        databaseRef.once('value', (snapshot) => {
          let fileName = snapshot.val().fileName as string;
          if(fileName.split('.').length < 2) {
            fileName = `${fileName}${this.selectedPluginType.name !== 'Installer' ? '.zip' : this.selectedOS === 'Windows' ? '.exe' : '.pkg'}`
          }
          this.downloadFromFirebaseStorage(`/${refPath}/${fileName}`, fileName);
        });
        this.showDownloadModal = false;
      },
      getOS() {
        let userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        } else {
          os = 'Windows';
        }

        return os;
      }
    },
    mounted() {
      this.selectedOS = this.getOS();
    }
});
